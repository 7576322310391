export const generateModulramImage = (model, traySize, trayPosition, chambers, base, pizzasQuantity, pizzasPosition) => {
  if (model === "classic") {
    switch (traySize) {
      case "40x60":
        switch (trayPosition) {
          case "1t40x60":
            switch (chambers) {
              case "1":
                if (base === "estrutura") {
                  return "https://res.cloudinary.com/de6biukqo/image/upload/v1623263371/Configurador-Modulram/Finais/ilustra_%C3%A4es_modulram_2021_1T_1C_-_C%C3%A9PULA-_ESTRUTURA_jbgmy1.svg"
                } else if (base === "estufa") {
                  return "https://res.cloudinary.com/de6biukqo/image/upload/v1623263385/Configurador-Modulram/Finais/ilustra_%C3%A4es_modulram_2021_1T_1C_-_C%C3%A9PULA-ESTUFA_lqmfkn.svg"
                }

              case "2":
                if (base === "estrutura") {
                  return "https://res.cloudinary.com/de6biukqo/image/upload/v1623263440/Configurador-Modulram/Finais/ilustra_%C3%A4es_modulram_2021_1T_2C_-_C%C3%A9PULA_-_ESTRUTURA_ct6uuh.svg"
                } else if (base === "estufa") {
                  return "https://res.cloudinary.com/de6biukqo/image/upload/v1623263452/Configurador-Modulram/Finais/ilustra_%C3%A4es_modulram_2021_1T_2C_-_C%C3%A9PULA_-_ESTUFA_ijd2tv.svg"
                }

              case "3":
                if (base === "estrutura") {
                  return "https://res.cloudinary.com/de6biukqo/image/upload/v1623263479/Configurador-Modulram/Finais/ilustra_%C3%A4es_modulram_2021_1T_3C_-_C%C3%A9PULA_-_ESTRUTURA_wuyiyf.svg"
                } else if (base === "estufa") {
                  return "https://res.cloudinary.com/de6biukqo/image/upload/v1623263491/Configurador-Modulram/Finais/ilustra_%C3%A4es_modulram_2021_1T_3C_-_C%C3%A9PULA_-_ESTUFA_cyj16q.svg"
                }

              case "4":
                if (base === "estrutura") {
                  return "https://res.cloudinary.com/de6biukqo/image/upload/v1623263517/Configurador-Modulram/Finais/ilustra_%C3%A4es_modulram_2021_1T_4C_-_C%C3%A9PULA_-_ESTRUTURA_kepwph.svg"
                } else if (base === "estufa") {
                  return "https://res.cloudinary.com/de6biukqo/image/upload/v1623263532/Configurador-Modulram/Finais/ilustra_%C3%A4es_modulram_2021_1T_4C_-_C%C3%A9PULA_-_ESTUFA_dufyth.svg"
                }

              case "5":
                if (base === "estrutura") {
                  return "https://res.cloudinary.com/de6biukqo/image/upload/v1623263566/Configurador-Modulram/Finais/ilustra_%C3%A4es_modulram_2021_1T_5C_-_C%C3%A9PULA_-_ESTRUTURA_divjll.svg"
                } else if (base === "estufa") {
                  return "https://res.cloudinary.com/de6biukqo/image/upload/v1623263581/Configurador-Modulram/Finais/ilustra_%C3%A4es_modulram_2021_1T_5C_-_C%C3%A9PULA_-_ESTUFA_bhfbkj.svg"
                }
            }

          case "1t40x60+1t40x60":
            switch (chambers) {
              case "1":
                if (base === "estrutura") {
                  return "https://res.cloudinary.com/de6biukqo/image/upload/v1623263934/Configurador-Modulram/Finais/ilustra_%C3%A4es_modulram_2021_1T_1C_-_C%C3%A9PULA-_ESTRUTURA_fzd7oh.svg"
                } else if (base === "estufa") {
                  return "https://res.cloudinary.com/de6biukqo/image/upload/v1623263983/Configurador-Modulram/Finais/ilustra_%C3%A4es_modulram_2021_1T_1C_-_C%C3%A9PULA-ESTUFA_inw5a5.svg"
                }

              case "2":
                if (base === "estrutura") {
                  return "https://res.cloudinary.com/de6biukqo/image/upload/v1623264005/Configurador-Modulram/Finais/ilustra_%C3%A4es_modulram_2021_1T_2C_-_C%C3%A9PULA_-_ESTRUTURA_ul6kb8.svg"
                } else if (base === "estufa") {
                  return "https://res.cloudinary.com/de6biukqo/image/upload/v1623264018/Configurador-Modulram/Finais/ilustra_%C3%A4es_modulram_2021_1T_2C_-_C%C3%A9PULA_-_ESTUFA_hvhqko.svg"
                }

              case "3":
                if (base === "estrutura") {
                  return "https://res.cloudinary.com/de6biukqo/image/upload/v1623264042/Configurador-Modulram/Finais/ilustra_%C3%A4es_modulram_2021_1T_3C_-_C%C3%A9PULA_-_ESTRUTURA_sieicz.svg"
                } else if (base === "estufa") {
                  return "https://res.cloudinary.com/de6biukqo/image/upload/v1623264075/Configurador-Modulram/Finais/ilustra_%C3%A4es_modulram_2021_1T_3C_-_C%C3%A9PULA_-_ESTUFA_vnkshs.svg"
                }

              case "4":
                if (base === "estrutura") {
                  return "https://res.cloudinary.com/de6biukqo/image/upload/v1623264096/Configurador-Modulram/Finais/ilustra_%C3%A4es_modulram_2021_1T_4C_-_C%C3%A9PULA_-_ESTRUTURA_pc64ru.svg"
                } else if (base === "estufa") {
                  return "https://res.cloudinary.com/de6biukqo/image/upload/v1623264118/Configurador-Modulram/Finais/ilustra_%C3%A4es_modulram_2021_1T_4C_-_C%C3%A9PULA_-_ESTUFA_xhumge.svg"
                }

              case "5":
                if (base === "estrutura") {
                  return "https://res.cloudinary.com/de6biukqo/image/upload/v1623264143/Configurador-Modulram/Finais/ilustra_%C3%A4es_modulram_2021_1T_5C_-_C%C3%A9PULA_-_ESTRUTURA_r3dj18.svg"
                } else if (base === "estufa") {
                  return "https://res.cloudinary.com/de6biukqo/image/upload/v1623264163/Configurador-Modulram/Finais/ilustra_%C3%A4es_modulram_2021_1T_5C_-_C%C3%A9PULA_-_ESTUFA_p6xy8o.svg"
                }
            }

          case "1t60x40+1t60x40":
            switch (chambers) {
              case "1":
                if (base === "estrutura") {
                  return "https://res.cloudinary.com/de6biukqo/image/upload/v1623319265/Configurador-Modulram/Finais/ilustra_%C3%A4es_modulram_2021_2T_1C_-_C%C3%A9PULA_-_ESTRUTURA_jt0hmh.svg"
                } else if (base === "estufa") {
                  return "https://res.cloudinary.com/de6biukqo/image/upload/v1623319282/Configurador-Modulram/Finais/ilustra_%C3%A4es_modulram_2021_2T_1C_-_C%C3%A9PULA_-_ESTUFA_ujcjkf.svg"
                }

              case "2":
                if (base === "estrutura") {
                  return "https://res.cloudinary.com/de6biukqo/image/upload/v1623319384/Configurador-Modulram/Finais/ilustra_%C3%A4es_modulram_2021_2T_2C_-_C%C3%A9PULA_-_ESTRUTURA_jrkpqy.svg"
                } else if (base === "estufa") {
                  return "https://res.cloudinary.com/de6biukqo/image/upload/v1623319400/Configurador-Modulram/Finais/ilustra_%C3%A4es_modulram_2021_2T_2C_-_C%C3%A9PULA_-_ESTUFA_dfnm8g.svg"
                }

              case "3":
                if (base === "estrutura") {
                  return "https://res.cloudinary.com/de6biukqo/image/upload/v1623319448/Configurador-Modulram/Finais/ilustra_%C3%A4es_modulram_2021_2T_3C_-_C%C3%A9PULA_-ESTRUTURA_irreit.svg"
                } else if (base === "estufa") {
                  return "https://res.cloudinary.com/de6biukqo/image/upload/v1623319413/Configurador-Modulram/Finais/ilustra_%C3%A4es_modulram_2021_2T_3C_-_C%C3%A9PULA_-_ESTUFA_fnqmvc.svg"
                }

              case "4":
                if (base === "estrutura") {
                  return "https://res.cloudinary.com/de6biukqo/image/upload/v1623319461/Configurador-Modulram/Finais/ilustra_%C3%A4es_modulram_2021_2T_4C_-_C%C3%A9PULA_-_ESTRUTURA_pma7lf.svg"
                } else if (base === "estufa") {
                  return "https://res.cloudinary.com/de6biukqo/image/upload/v1623319474/Configurador-Modulram/Finais/ilustra_%C3%A4es_modulram_2021_2T_4C_-_C%C3%A9PULA_-_ESTUFA_xtqwld.svg"
                }

              case "5":
                if (base === "estrutura") {
                  return "https://res.cloudinary.com/de6biukqo/image/upload/v1623319491/Configurador-Modulram/Finais/ilustra_%C3%A4es_modulram_2021_2T_5C_-_C%C3%A9PULA_-_ESTRUTURA_dcilyn.svg"
                } else if (base === "estufa") {
                  return "https://res.cloudinary.com/de6biukqo/image/upload/v1623319508/Configurador-Modulram/Finais/ilustra_%C3%A4es_modulram_2021_2T_5C_-_C%C3%A9PULA_-_ESTUFA_ihra6l.svg"
                }
            }

          case "2t40x60":
            switch (chambers) {
              case "1":
                if (base === "estrutura") {
                  return "https://res.cloudinary.com/de6biukqo/image/upload/v1623319611/Configurador-Modulram/Finais/ilustra_%C3%A4es_modulram_2021_2T_1C_-_C%C3%A9PULA_-_ESTRUTURA_pfkjai.svg"
                } else if (base === "estufa") {
                  return "https://res.cloudinary.com/de6biukqo/image/upload/v1623319622/Configurador-Modulram/Finais/ilustra_%C3%A4es_modulram_2021_2T_1C_-_C%C3%A9PULA_-_ESTUFA_g8q4jb.svg"
                }

              case "2":
                if (base === "estrutura") {
                  return "https://res.cloudinary.com/de6biukqo/image/upload/v1623319638/Configurador-Modulram/Finais/ilustra_%C3%A4es_modulram_2021_2T_2C_-_C%C3%A9PULA_-_ESTRUTURA_cgilie.svg"
                } else if (base === "estufa") {
                  return "https://res.cloudinary.com/de6biukqo/image/upload/v1623319653/Configurador-Modulram/Finais/ilustra_%C3%A4es_modulram_2021_2T_2C_-_C%C3%A9PULA_-_ESTUFA_dvzfwb.svg"
                }

              case "3":
                if (base === "estrutura") {
                  return "https://res.cloudinary.com/de6biukqo/image/upload/v1623319689/Configurador-Modulram/Finais/ilustra_%C3%A4es_modulram_2021_2T_3C_-_C%C3%A9PULA_-ESTRUTURA_rtpgtv.svg"
                } else if (base === "estufa") {
                  return "https://res.cloudinary.com/de6biukqo/image/upload/v1623319677/Configurador-Modulram/Finais/ilustra_%C3%A4es_modulram_2021_2T_3C_-_C%C3%A9PULA_-_ESTUFA_ezmbui.svg"
                }

              case "4":
                if (base === "estrutura") {
                  return "https://res.cloudinary.com/de6biukqo/image/upload/v1623319708/Configurador-Modulram/Finais/ilustra_%C3%A4es_modulram_2021_2T_4C_-_C%C3%A9PULA_-_ESTRUTURA_fyp7vd.svg"
                } else if (base === "estufa") {
                  return "https://res.cloudinary.com/de6biukqo/image/upload/v1623319723/Configurador-Modulram/Finais/ilustra_%C3%A4es_modulram_2021_2T_4C_-_C%C3%A9PULA_-_ESTUFA_bdcjqx.svg"
                }

              case "5":
                if (base === "estrutura") {
                  return "https://res.cloudinary.com/de6biukqo/image/upload/v1623320670/Configurador-Modulram/Finais/ilustra_%C3%A4es_modulram_2021_2T_5C_-_C%C3%A9PULA_-_ESTRUTURA_dqvwde.svg"
                } else if (base === "estufa") {
                  return "https://res.cloudinary.com/de6biukqo/image/upload/v1623320685/Configurador-Modulram/Finais/ilustra_%C3%A4es_modulram_2021_2T_5C_-_C%C3%A9PULA_-_ESTUFA_wnyqor.svg"
                }
            }

          case "3t40x60":
            switch (chambers) {
              case "1":
                if (base === "estrutura") {
                  return "https://res.cloudinary.com/de6biukqo/image/upload/v1623320728/Configurador-Modulram/Finais/ilustra_%C3%A4es_modulram_2021_3T_1C_-_C%C3%A9PULA_-_ESTRUTURA_yz4aq1.svg"
                } else if (base === "estufa") {
                  return "https://res.cloudinary.com/de6biukqo/image/upload/v1623320741/Configurador-Modulram/Finais/ilustra_%C3%A4es_modulram_2021_3T_1C_-_C%C3%A9PULA_-_ESTUFA_itkv9z.svg"
                }

              case "2":
                if (base === "estrutura") {
                  return "https://res.cloudinary.com/de6biukqo/image/upload/v1623320895/Configurador-Modulram/Finais/ilustra_%C3%A4es_modulram_2021_3T_2C_-_C%C3%A9PULA_-_ESTRUTURA_cxrgsp.svg"
                } else if (base === "estufa") {
                  return "https://res.cloudinary.com/de6biukqo/image/upload/v1623320906/Configurador-Modulram/Finais/ilustra_%C3%A4es_modulram_2021_3T_2C_-_C%C3%A9PULA_-_ESTUFA_vqdsqi.svg"
                }

              case "3":
                if (base === "estrutura") {
                  return "https://res.cloudinary.com/de6biukqo/image/upload/v1623320928/Configurador-Modulram/Finais/ilustra_%C3%A4es_modulram_2021_3T_3C_-_C%C3%A9PULA_-_ESTRUTURA_rht6n8.svg"
                } else if (base === "estufa") {
                  return "https://res.cloudinary.com/de6biukqo/image/upload/v1623320942/Configurador-Modulram/Finais/ilustra_%C3%A4es_modulram_2021_3T_3C_-_C%C3%A9PULA_-_ESTUFA_dnrkpn.svg"
                }

              case "4":
                if (base === "estrutura") {
                  return "https://res.cloudinary.com/de6biukqo/image/upload/v1623320957/Configurador-Modulram/Finais/ilustra_%C3%A4es_modulram_2021_3T_4C_-_C%C3%A9PULA_-_ESTRUTURA_o8bjfb.svg"
                } else if (base === "estufa") {
                  return "https://res.cloudinary.com/de6biukqo/image/upload/v1623320972/Configurador-Modulram/Finais/ilustra_%C3%A4es_modulram_2021_3T_4C_-_C%C3%A9PULA_-_ESTUFA_knwfck.svg"
                }

              case "5":
                if (base === "estrutura") {
                  return "https://res.cloudinary.com/de6biukqo/image/upload/v1623320994/Configurador-Modulram/Finais/ilustra_%C3%A4es_modulram_2021_3T_5C_-_C%C3%A9PULA_-_ESTRUTURA_s5pqsd.svg"
                } else if (base === "estufa") {
                  return "https://res.cloudinary.com/de6biukqo/image/upload/v1623321011/Configurador-Modulram/Finais/ilustra_%C3%A4es_modulram_2021_3T_5C_-_C%C3%A9PULA_-_ESTUFA_bsvzmv.svg"
                }
            }

          case "2t40x60+2t40x60":
            switch (chambers) {
              case "1":
                if (base === "estrutura") {
                  return "https://res.cloudinary.com/de6biukqo/image/upload/v1623321089/Configurador-Modulram/Finais/ilustra_%C3%A4es_modulram_2021_2T_1C_-_C%C3%A9PULA_-_ESTRUTURA_htp4n1.svg"
                } else if (base === "estufa") {
                  return "https://res.cloudinary.com/de6biukqo/image/upload/v1623321101/Configurador-Modulram/Finais/ilustra_%C3%A4es_modulram_2021_2T_1C_-_C%C3%A9PULA_-_ESTUFA_ukrlia.svg"
                }

              case "2":
                if (base === "estrutura") {
                  return "https://res.cloudinary.com/de6biukqo/image/upload/v1623321119/Configurador-Modulram/Finais/ilustra_%C3%A4es_modulram_2021_2T_2C_-_C%C3%A9PULA_-_ESTRUTURA_opdabp.svg"
                } else if (base === "estufa") {
                  return "https://res.cloudinary.com/de6biukqo/image/upload/v1623321135/Configurador-Modulram/Finais/ilustra_%C3%A4es_modulram_2021_2T_2C_-_C%C3%A9PULA_-_ESTUFA_hj3nba.svg"
                }

              case "3":
                if (base === "estrutura") {
                  return "https://res.cloudinary.com/de6biukqo/image/upload/v1623321157/Configurador-Modulram/Finais/ilustra_%C3%A4es_modulram_2021_2T_3C_-_C%C3%A9PULA_-ESTRUTURA_uknt3c.svg"
                } else if (base === "estufa") {
                  return "https://res.cloudinary.com/de6biukqo/image/upload/v1623321175/Configurador-Modulram/Finais/ilustra_%C3%A4es_modulram_2021_2T_3C_-_C%C3%A9PULA_-_ESTUFA_pew9xz.svg"
                }

              case "4":
                if (base === "estrutura") {
                  return "https://res.cloudinary.com/de6biukqo/image/upload/v1623321197/Configurador-Modulram/Finais/ilustra_%C3%A4es_modulram_2021_2T_4C_-_C%C3%A9PULA_-_ESTRUTURA_uozp0v.svg"
                } else if (base === "estufa") {
                  return "https://res.cloudinary.com/de6biukqo/image/upload/v1623321211/Configurador-Modulram/Finais/ilustra_%C3%A4es_modulram_2021_2T_4C_-_C%C3%A9PULA_-_ESTUFA_c4qzir.svg"
                }

              case "5":
                if (base === "estrutura") {
                  return "https://res.cloudinary.com/de6biukqo/image/upload/v1623321230/Configurador-Modulram/Finais/ilustra_%C3%A4es_modulram_2021_2T_5C_-_C%C3%A9PULA_-_ESTRUTURA_adp6l6.svg"
                } else if (base === "estufa") {
                  return "https://res.cloudinary.com/de6biukqo/image/upload/v1623321247/Configurador-Modulram/Finais/ilustra_%C3%A4es_modulram_2021_2T_5C_-_C%C3%A9PULA_-_ESTUFA_bfr779.svg"
                }
            }

          case "2t60x40+2t60x40":
            switch (chambers) {
              case "1":
                if (base === "estrutura") {
                  return "https://res.cloudinary.com/de6biukqo/image/upload/v1623321340/Configurador-Modulram/Finais/ilustra_%C3%A4es_modulram_2021_2T_1C_-_C%C3%A9PULA_-_ESTRUTURA_z5kkrl.svg"
                } else if (base === "estufa") {
                  return "https://res.cloudinary.com/de6biukqo/image/upload/v1623321355/Configurador-Modulram/Finais/ilustra_%C3%A4es_modulram_2021_2T_1C_-_C%C3%A9PULA_-_ESTUFA_eqbbio.svg"
                }

              case "2":
                if (base === "estrutura") {
                  return "https://res.cloudinary.com/de6biukqo/image/upload/v1623321372/Configurador-Modulram/Finais/ilustra_%C3%A4es_modulram_2021_2T_2C_-_C%C3%A9PULA_-_ESTRUTURA_jlbokn.svg"
                } else if (base === "estufa") {
                  return "https://res.cloudinary.com/de6biukqo/image/upload/v1623321387/Configurador-Modulram/Finais/ilustra_%C3%A4es_modulram_2021_2T_2C_-_C%C3%A9PULA_-_ESTUFA_qacj5o.svg"
                }

              case "3":
                if (base === "estrutura") {
                  return "https://res.cloudinary.com/de6biukqo/image/upload/v1623321408/Configurador-Modulram/Finais/ilustra_%C3%A4es_modulram_2021_2T_3C_-_C%C3%A9PULA_-ESTRUTURA_h1d8wl.svg"
                } else if (base === "estufa") {
                  return "https://res.cloudinary.com/de6biukqo/image/upload/v1623321424/Configurador-Modulram/Finais/ilustra_%C3%A4es_modulram_2021_2T_3C_-_C%C3%A9PULA_-_ESTUFA_owuqm9.svg"
                }

              case "4":
                if (base === "estrutura") {
                  return "https://res.cloudinary.com/de6biukqo/image/upload/v1623321440/Configurador-Modulram/Finais/ilustra_%C3%A4es_modulram_2021_2T_4C_-_C%C3%A9PULA_-_ESTRUTURA_lxuvnd.svg"
                } else if (base === "estufa") {
                  return "https://res.cloudinary.com/de6biukqo/image/upload/v1623321455/Configurador-Modulram/Finais/ilustra_%C3%A4es_modulram_2021_2T_4C_-_C%C3%A9PULA_-_ESTUFA_rxqb9h.svg"
                }

              case "5":
                if (base === "estrutura") {
                  return "https://res.cloudinary.com/de6biukqo/image/upload/v1623321474/Configurador-Modulram/Finais/ilustra_%C3%A4es_modulram_2021_2T_5C_-_C%C3%A9PULA_-_ESTRUTURA_ibx5fj.svg"
                } else if (base === "estufa") {
                  return "https://res.cloudinary.com/de6biukqo/image/upload/v1623321495/Configurador-Modulram/Finais/ilustra_%C3%A4es_modulram_2021_2T_5C_-_C%C3%A9PULA_-_ESTUFA_nhlxwc.svg"
                }
            }

          case "1t40x60+1t40x60+1t40x60+1t40x60":
            switch (chambers) {
              case "1":
                if (base === "estrutura") {
                  return "https://res.cloudinary.com/de6biukqo/image/upload/v1623321617/Configurador-Modulram/Finais/ilustra_%C3%A4es_modulram_2021_1T_1C_-_C%C3%A9PULA-_ESTRUTURA_wlc0df.svg"
                } else if (base === "estufa") {
                  return "https://res.cloudinary.com/de6biukqo/image/upload/v1623321633/Configurador-Modulram/Finais/ilustra_%C3%A4es_modulram_2021_1T_1C_-_C%C3%A9PULA-ESTUFA_zsgyq1.svg"
                }

              case "2":
                if (base === "estrutura") {
                  return "https://res.cloudinary.com/de6biukqo/image/upload/v1623321649/Configurador-Modulram/Finais/ilustra_%C3%A4es_modulram_2021_1T_2C_-_C%C3%A9PULA_-_ESTRUTURA_hguv6n.svg"
                } else if (base === "estufa") {
                  return "https://res.cloudinary.com/de6biukqo/image/upload/v1623321663/Configurador-Modulram/Finais/ilustra_%C3%A4es_modulram_2021_1T_2C_-_C%C3%A9PULA_-_ESTUFA_c46hk9.svg"
                }

              case "3":
                if (base === "estrutura") {
                  return "https://res.cloudinary.com/de6biukqo/image/upload/v1623321681/Configurador-Modulram/Finais/ilustra_%C3%A4es_modulram_2021_1T_3C_-_C%C3%A9PULA_-_ESTRUTURA_trmdoj.svg"
                } else if (base === "estufa") {
                  return "https://res.cloudinary.com/de6biukqo/image/upload/v1623321681/Configurador-Modulram/Finais/ilustra_%C3%A4es_modulram_2021_1T_3C_-_C%C3%A9PULA_-_ESTUFA_wwoqru.svg"
                }

              case "4":
                if (base === "estrutura") {
                  return "https://res.cloudinary.com/de6biukqo/image/upload/v1623321704/Configurador-Modulram/Finais/ilustra_%C3%A4es_modulram_2021_1T_4C_-_C%C3%A9PULA_-_ESTRUTURA_npjgmc.svg"
                } else if (base === "estufa") {
                  return "https://res.cloudinary.com/de6biukqo/image/upload/v1623321704/Configurador-Modulram/Finais/ilustra_%C3%A4es_modulram_2021_1T_4C_-_C%C3%A9PULA_-_ESTUFA_gmpjwp.svg"
                }

              case "5":
                if (base === "estrutura") {
                  return "https://res.cloudinary.com/de6biukqo/image/upload/v1623321744/Configurador-Modulram/Finais/ilustra_%C3%A4es_modulram_2021_1T_5C_-_C%C3%A9PULA_-_ESTRUTURA_wm6w8k.svg"
                } else if (base === "estufa") {
                  return "https://res.cloudinary.com/de6biukqo/image/upload/v1623321744/Configurador-Modulram/Finais/ilustra_%C3%A4es_modulram_2021_1T_5C_-_C%C3%A9PULA_-_ESTUFA_i4pugu.svg"
                }
            }

          case "3t40x60+3t40x60":
            switch (chambers) {
              case "1":
                if (base === "estrutura") {
                  return "https://res.cloudinary.com/de6biukqo/image/upload/v1623321874/Configurador-Modulram/Finais/ilustra_%C3%A4es_modulram_2021_3T_1C_-_C%C3%A9PULA_-_ESTRUTURA_js3l5u.svg"
                } else if (base === "estufa") {
                  return "https://res.cloudinary.com/de6biukqo/image/upload/v1623321874/Configurador-Modulram/Finais/ilustra_%C3%A4es_modulram_2021_3T_1C_-_C%C3%A9PULA_-_ESTUFA_mz0foh.svg"
                }

              case "2":
                if (base === "estrutura") {
                  return "https://res.cloudinary.com/de6biukqo/image/upload/v1623321904/Configurador-Modulram/Finais/ilustra_%C3%A4es_modulram_2021_3T_2C_-_C%C3%A9PULA_-_ESTRUTURA_le0c1p.svg"
                } else if (base === "estufa") {
                  return "https://res.cloudinary.com/de6biukqo/image/upload/v1623321904/Configurador-Modulram/Finais/ilustra_%C3%A4es_modulram_2021_3T_2C_-_C%C3%A9PULA_-_ESTUFA_se2zep.svg"
                }

              case "3":
                if (base === "estrutura") {
                  return "https://res.cloudinary.com/de6biukqo/image/upload/v1623321934/Configurador-Modulram/Finais/ilustra_%C3%A4es_modulram_2021_3T_3C_-_C%C3%A9PULA_-_ESTRUTURA_l645ae.svg"
                } else if (base === "estufa") {
                  return "https://res.cloudinary.com/de6biukqo/image/upload/v1623321959/Configurador-Modulram/Finais/ilustra_%C3%A4es_modulram_2021_3T_3C_-_C%C3%A9PULA_-_ESTUFA_ict0qy.svg"
                }

              case "4":
                if (base === "estrutura") {
                  return "https://res.cloudinary.com/de6biukqo/image/upload/v1623321975/Configurador-Modulram/Finais/ilustra_%C3%A4es_modulram_2021_3T_4C_-_C%C3%A9PULA_-_ESTRUTURA_wlbbgm.svg"
                } else if (base === "estufa") {
                  return "https://res.cloudinary.com/de6biukqo/image/upload/v1623321975/Configurador-Modulram/Finais/ilustra_%C3%A4es_modulram_2021_3T_4C_-_C%C3%A9PULA_-_ESTUFA_alk8kv.svg"
                }

              case "5":
                return "https://res.cloudinary.com/de6biukqo/image/upload/v1623322001/Configurador-Modulram/Finais/ilustra_%C3%A4es_modulram_2021_3T_5C_-_C%C3%A9PULA_-_ESTRUTURA_apklvr.svg"
            }

          case "3t60x40+3t60x40":
            switch (chambers) {
              case "1":
                if (base === "estrutura") {
                  return "https://res.cloudinary.com/de6biukqo/image/upload/v1623322075/Configurador-Modulram/Finais/ilustra_%C3%A4es_modulram_2021_3T_1C_-_C%C3%A9PULA_-_ESTRUTURA_diw3ag.svg"
                } else if (base === "estufa") {
                  return "https://res.cloudinary.com/de6biukqo/image/upload/v1623322075/Configurador-Modulram/Finais/ilustra_%C3%A4es_modulram_2021_3T_1C_-_C%C3%A9PULA_-_ESTUFA_zehe4c.svg"
                }

              case "2":
                if (base === "estrutura") {
                  return "https://res.cloudinary.com/de6biukqo/image/upload/v1623322102/Configurador-Modulram/Finais/ilustra_%C3%A4es_modulram_2021_3T_2C_-_C%C3%A9PULA_-_ESTRUTURA_qq6pkq.svg"
                } else if (base === "estufa") {
                  return "https://res.cloudinary.com/de6biukqo/image/upload/v1623322102/Configurador-Modulram/Finais/ilustra_%C3%A4es_modulram_2021_3T_2C_-_C%C3%A9PULA_-_ESTUFA_svhdq0.svg"
                }

              case "3":
                if (base === "estrutura") {
                  return "https://res.cloudinary.com/de6biukqo/image/upload/v1623322127/Configurador-Modulram/Finais/ilustra_%C3%A4es_modulram_2021_3T_3C_-_C%C3%A9PULA_-_ESTRUTURA_c3q64n.svg"
                } else if (base === "estufa") {
                  return "https://res.cloudinary.com/de6biukqo/image/upload/v1623322127/Configurador-Modulram/Finais/ilustra_%C3%A4es_modulram_2021_3T_3C_-_C%C3%A9PULA_-_ESTUFA_vs5qnc.svg"
                }

              case "4":
                if (base === "estrutura") {
                  return "https://res.cloudinary.com/de6biukqo/image/upload/v1623322152/Configurador-Modulram/Finais/ilustra_%C3%A4es_modulram_2021_3T_4C_-_C%C3%A9PULA_-_ESTRUTURA_ogjkdg.svg"
                } else if (base === "estufa") {
                  return "https://res.cloudinary.com/de6biukqo/image/upload/v1623322152/Configurador-Modulram/Finais/ilustra_%C3%A4es_modulram_2021_3T_4C_-_C%C3%A9PULA_-_ESTUFA_kr2chm.svg"
                }

              case "5":
                if (base === "estrutura") {
                  return "https://res.cloudinary.com/de6biukqo/image/upload/v1623322178/Configurador-Modulram/Finais/ilustra_%C3%A4es_modulram_2021_3T_5C_-_C%C3%A9PULA_-_ESTRUTURA_hvar5j.svg"
                } else if (base === "estufa") {
                  return "https://res.cloudinary.com/de6biukqo/image/upload/v1623322178/Configurador-Modulram/Finais/ilustra_%C3%A4es_modulram_2021_3T_5C_-_C%C3%A9PULA_-_ESTUFA_uoam4y.svg"
                }
            }

          case "4t60x40+4t60x40":
            switch (chambers) {
              case "1":
                if (base === "estrutura") {
                  return "https://res.cloudinary.com/de6biukqo/image/upload/v1623322242/Configurador-Modulram/Finais/ilustra_%C3%A4es_modulram_2021_2T_1C_-_C%C3%A9PULA_-_ESTRUTURA_feoeep.svg"
                } else if (base === "estufa") {
                  return "https://res.cloudinary.com/de6biukqo/image/upload/v1623322243/Configurador-Modulram/Finais/ilustra_%C3%A4es_modulram_2021_2T_1C_-_C%C3%A9PULA_-_ESTUFA_ub985b.svg"
                }

              case "2":
                if (base === "estrutura") {
                  return "https://res.cloudinary.com/de6biukqo/image/upload/v1623322270/Configurador-Modulram/Finais/ilustra_%C3%A4es_modulram_2021_2T_2C_-_C%C3%A9PULA_-_ESTRUTURA_lz1mvx.svg"
                } else if (base === "estufa") {
                  return "https://res.cloudinary.com/de6biukqo/image/upload/v1623322270/Configurador-Modulram/Finais/ilustra_%C3%A4es_modulram_2021_2T_2C_-_C%C3%A9PULA_-_ESTUFA_jis6vr.svg"
                }

              case "3":
                if (base === "estrutura") {
                  return "https://res.cloudinary.com/de6biukqo/image/upload/v1623322297/Configurador-Modulram/Finais/ilustra_%C3%A4es_modulram_2021_2T_3C_-_C%C3%A9PULA_-ESTRUTURA_mgum8f.svg"
                } else if (base === "estufa") {
                  return "https://res.cloudinary.com/de6biukqo/image/upload/v1623322297/Configurador-Modulram/Finais/ilustra_%C3%A4es_modulram_2021_2T_3C_-_C%C3%A9PULA_-_ESTUFA_iti0ky.svg"
                }

              case "4":
                if (base === "estrutura") {
                  return "https://res.cloudinary.com/de6biukqo/image/upload/v1623322319/Configurador-Modulram/Finais/ilustra_%C3%A4es_modulram_2021_2T_4C_-_C%C3%A9PULA_-_ESTRUTURA_un41x5.svg"
                } else if (base === "estufa") {
                  return "https://res.cloudinary.com/de6biukqo/image/upload/v1623322320/Configurador-Modulram/Finais/ilustra_%C3%A4es_modulram_2021_2T_4C_-_C%C3%A9PULA_-_ESTUFA_gmjaiv.svg"
                }

              case "5":
                return "https://res.cloudinary.com/de6biukqo/image/upload/v1623322341/Configurador-Modulram/Finais/ilustra_%C3%A4es_modulram_2021_2T_5C_-_C%C3%A9PULA_-_ESTRUTURA_gbvyjz.svg"
            }
        }

      case "45x75":
        switch (trayPosition) {
          case "1t45x75":
            switch (chambers) {
              case "1":
                if (base === "estrutura") {
                  return "https://res.cloudinary.com/de6biukqo/image/upload/v1623322595/Configurador-Modulram/Finais/ilustra_%C3%A4es_modulram_2021_1T_1C_-_C%C3%A9PULA-_ESTRUTURA_ezbsvu.svg"
                } else if (base === "estufa") {
                  return "https://res.cloudinary.com/de6biukqo/image/upload/v1623322596/Configurador-Modulram/Finais/ilustra_%C3%A4es_modulram_2021_1T_1C_-_C%C3%A9PULA-ESTUFA_hxnnuf.svg"
                }

              case "2":
                if (base === "estrutura") {
                  return "https://res.cloudinary.com/de6biukqo/image/upload/v1623322618/Configurador-Modulram/Finais/ilustra_%C3%A4es_modulram_2021_1T_2C_-_C%C3%A9PULA_-_ESTRUTURA_wxfovu.svg"
                } else if (base === "estufa") {
                  return "https://res.cloudinary.com/de6biukqo/image/upload/v1623322618/Configurador-Modulram/Finais/ilustra_%C3%A4es_modulram_2021_1T_2C_-_C%C3%A9PULA_-_ESTUFA_p0fmk0.svg"
                }

              case "3":
                if (base === "estrutura") {
                  return "https://res.cloudinary.com/de6biukqo/image/upload/v1623322643/Configurador-Modulram/Finais/ilustra_%C3%A4es_modulram_2021_1T_3C_-_C%C3%A9PULA_-_ESTRUTURA_n3x1cf.svg"
                } else if (base === "estufa") {
                  return "https://res.cloudinary.com/de6biukqo/image/upload/v1623322643/Configurador-Modulram/Finais/ilustra_%C3%A4es_modulram_2021_1T_3C_-_C%C3%A9PULA_-_ESTUFA_zx1nhm.svg"
                }

              case "4":
                if (base === "estrutura") {
                  return "https://res.cloudinary.com/de6biukqo/image/upload/v1623322664/Configurador-Modulram/Finais/ilustra_%C3%A4es_modulram_2021_1T_4C_-_C%C3%A9PULA_-_ESTRUTURA_crvtt1.svg"
                } else if (base === "estufa") {
                  return "https://res.cloudinary.com/de6biukqo/image/upload/v1623322664/Configurador-Modulram/Finais/ilustra_%C3%A4es_modulram_2021_1T_4C_-_C%C3%A9PULA_-_ESTUFA_yzz4as.svg"
                }

              case "5":
                if (base === "estrutura") {
                  return "https://res.cloudinary.com/de6biukqo/image/upload/v1623322689/Configurador-Modulram/Finais/ilustra_%C3%A4es_modulram_2021_1T_5C_-_C%C3%A9PULA_-_ESTRUTURA_txg9gq.svg"
                } else if (base === "estufa") {
                  return "https://res.cloudinary.com/de6biukqo/image/upload/v1623322689/Configurador-Modulram/Finais/ilustra_%C3%A4es_modulram_2021_1T_5C_-_C%C3%A9PULA_-_ESTUFA_yimn2p.svg"
                }
            }

          case "1t75x45+1t75x45":
            switch (chambers) {
              case "1":
                if (base === "estrutura") {
                  return "https://res.cloudinary.com/de6biukqo/image/upload/v1623322759/Configurador-Modulram/Finais/ilustra_%C3%A4es_modulram_2021_2T_1C_-_C%C3%A9PULA_-_ESTRUTURA_bvzxnu.svg"
                } else if (base === "estufa") {
                  return "https://res.cloudinary.com/de6biukqo/image/upload/v1623322759/Configurador-Modulram/Finais/ilustra_%C3%A4es_modulram_2021_2T_1C_-_C%C3%A9PULA_-_ESTUFA_jn1n2y.svg"
                }

              case "2":
                if (base === "estrutura") {
                  return "https://res.cloudinary.com/de6biukqo/image/upload/v1623322830/Configurador-Modulram/Finais/ilustra_%C3%A4es_modulram_2021_2T_2C_-_C%C3%A9PULA_-_ESTRUTURA_yqqitt.svg"
                } else if (base === "estufa") {
                  return "https://res.cloudinary.com/de6biukqo/image/upload/v1623322830/Configurador-Modulram/Finais/ilustra_%C3%A4es_modulram_2021_2T_2C_-_C%C3%A9PULA_-_ESTUFA_l8kg5d.svg"
                }

              case "3":
                if (base === "estrutura") {
                  return "https://res.cloudinary.com/de6biukqo/image/upload/v1623322851/Configurador-Modulram/Finais/ilustra_%C3%A4es_modulram_2021_2T_3C_-_C%C3%A9PULA_-ESTRUTURA_r3dott.svg"
                } else if (base === "estufa") {
                  return "https://res.cloudinary.com/de6biukqo/image/upload/v1623322852/Configurador-Modulram/Finais/ilustra_%C3%A4es_modulram_2021_2T_3C_-_C%C3%A9PULA_-_ESTUFA_xjqvza.svg"
                }

              case "4":
                if (base === "estrutura") {
                  return "https://res.cloudinary.com/de6biukqo/image/upload/v1623322874/Configurador-Modulram/Finais/ilustra_%C3%A4es_modulram_2021_2T_4C_-_C%C3%A9PULA_-_ESTRUTURA_oiyhqq.svg"
                } else if (base === "estufa") {
                  return "https://res.cloudinary.com/de6biukqo/image/upload/v1623322874/Configurador-Modulram/Finais/ilustra_%C3%A4es_modulram_2021_2T_4C_-_C%C3%A9PULA_-_ESTUFA_sub90v.svg"
                }

              case "5":
                if (base === "estrutura") {
                  return "https://res.cloudinary.com/de6biukqo/image/upload/v1623322898/Configurador-Modulram/Finais/ilustra_%C3%A4es_modulram_2021_2T_5C_-_C%C3%A9PULA_-_ESTRUTURA_uzb8qx.svg"
                } else if (base === "estufa") {
                  return "https://res.cloudinary.com/de6biukqo/image/upload/v1623322897/Configurador-Modulram/Finais/ilustra_%C3%A4es_modulram_2021_2T_5C_-_C%C3%A9PULA_-_ESTUFA_ltb8k8.svg"
                }
            }

          case "1t45x75+1t45x75":
            switch (chambers) {
              case "1":
                if (base === "estrutura") {
                  return "https://res.cloudinary.com/de6biukqo/image/upload/v1623322966/Configurador-Modulram/Finais/ilustra_%C3%A4es_modulram_2021_1T_1C_-_C%C3%A9PULA-_ESTRUTURA_eryjhl.svg"
                } else if (base === "estufa") {
                  return "https://res.cloudinary.com/de6biukqo/image/upload/v1623322967/Configurador-Modulram/Finais/ilustra_%C3%A4es_modulram_2021_1T_1C_-_C%C3%A9PULA-ESTUFA_xxbvdd.svg"
                }

              case "2":
                if (base === "estrutura") {
                  return "https://res.cloudinary.com/de6biukqo/image/upload/v1623323018/Configurador-Modulram/Finais/ilustra_%C3%A4es_modulram_2021_1T_2C_-_C%C3%A9PULA_-_ESTRUTURA_opu3pg.svg"
                } else if (base === "estufa") {
                  return "https://res.cloudinary.com/de6biukqo/image/upload/v1623323018/Configurador-Modulram/Finais/ilustra_%C3%A4es_modulram_2021_1T_2C_-_C%C3%A9PULA_-_ESTUFA_qyvigq.svg"
                }

              case "3":
                if (base === "estrutura") {
                  return "https://res.cloudinary.com/de6biukqo/image/upload/v1623323041/Configurador-Modulram/Finais/ilustra_%C3%A4es_modulram_2021_1T_3C_-_C%C3%A9PULA_-_ESTRUTURA_cf4nib.svg"
                } else if (base === "estufa") {
                  return "https://res.cloudinary.com/de6biukqo/image/upload/v1623323041/Configurador-Modulram/Finais/ilustra_%C3%A4es_modulram_2021_1T_3C_-_C%C3%A9PULA_-_ESTUFA_pih3zs.svg"
                }

              case "4":
                if (base === "estrutura") {
                  return "https://res.cloudinary.com/de6biukqo/image/upload/v1623323069/Configurador-Modulram/Finais/ilustra_%C3%A4es_modulram_2021_1T_4C_-_C%C3%A9PULA_-_ESTRUTURA_hl9ajs.svg"
                } else if (base === "estufa") {
                  return "https://res.cloudinary.com/de6biukqo/image/upload/v1623323069/Configurador-Modulram/Finais/ilustra_%C3%A4es_modulram_2021_1T_4C_-_C%C3%A9PULA_-_ESTUFA_hzjkwk.svg"
                }

              case "5":
                if (base === "estrutura") {
                  return "https://res.cloudinary.com/de6biukqo/image/upload/v1623323089/Configurador-Modulram/Finais/ilustra_%C3%A4es_modulram_2021_1T_5C_-_C%C3%A9PULA_-_ESTRUTURA_lzrlee.svg"
                } else if (base === "estufa") {
                  return "https://res.cloudinary.com/de6biukqo/image/upload/v1623323089/Configurador-Modulram/Finais/ilustra_%C3%A4es_modulram_2021_1T_5C_-_C%C3%A9PULA_-_ESTUFA_zioyum.svg"
                }
            }

          case "2t45x75":
            switch (chambers) {
              case "1":
                if (base === "estrutura") {
                  return "https://res.cloudinary.com/de6biukqo/image/upload/v1623323161/Configurador-Modulram/Finais/ilustra_%C3%A4es_modulram_2021_2T_1C_-_C%C3%A9PULA_-_ESTRUTURA_ta8ms9.svg"
                } else if (base === "estufa") {
                  return "https://res.cloudinary.com/de6biukqo/image/upload/v1623323161/Configurador-Modulram/Finais/ilustra_%C3%A4es_modulram_2021_2T_1C_-_C%C3%A9PULA_-_ESTUFA_arxnsl.svg"
                }

              case "2":
                if (base === "estrutura") {
                  return "https://res.cloudinary.com/de6biukqo/image/upload/v1623323183/Configurador-Modulram/Finais/ilustra_%C3%A4es_modulram_2021_2T_2C_-_C%C3%A9PULA_-_ESTRUTURA_jwlb5v.svg"
                } else if (base === "estufa") {
                  return "https://res.cloudinary.com/de6biukqo/image/upload/v1623323183/Configurador-Modulram/Finais/ilustra_%C3%A4es_modulram_2021_2T_2C_-_C%C3%A9PULA_-_ESTUFA_pjbo0f.svg"
                }

              case "3":
                if (base === "estrutura") {
                  return "https://res.cloudinary.com/de6biukqo/image/upload/v1623323207/Configurador-Modulram/Finais/ilustra_%C3%A4es_modulram_2021_2T_3C_-_C%C3%A9PULA_-ESTRUTURA_cargg1.svg"
                } else if (base === "estufa") {
                  return "https://res.cloudinary.com/de6biukqo/image/upload/v1623323207/Configurador-Modulram/Finais/ilustra_%C3%A4es_modulram_2021_2T_3C_-_C%C3%A9PULA_-_ESTUFA_vejxt5.svg"
                }

              case "4":
                if (base === "estrutura") {
                  return "https://res.cloudinary.com/de6biukqo/image/upload/v1623323227/Configurador-Modulram/Finais/ilustra_%C3%A4es_modulram_2021_2T_4C_-_C%C3%A9PULA_-_ESTRUTURA_cekjyb.svg"
                } else if (base === "estufa") {
                  return "https://res.cloudinary.com/de6biukqo/image/upload/v1623323227/Configurador-Modulram/Finais/ilustra_%C3%A4es_modulram_2021_2T_4C_-_C%C3%A9PULA_-_ESTUFA_stmdk6.svg"
                }

              case "5":
                if (base === "estrutura") {
                  return "https://res.cloudinary.com/de6biukqo/image/upload/v1623323248/Configurador-Modulram/Finais/ilustra_%C3%A4es_modulram_2021_2T_5C_-_C%C3%A9PULA_-_ESTRUTURA_syorra.svg"
                } else if (base === "estufa") {
                  return "https://res.cloudinary.com/de6biukqo/image/upload/v1623323248/Configurador-Modulram/Finais/ilustra_%C3%A4es_modulram_2021_2T_5C_-_C%C3%A9PULA_-_ESTUFA_djhh1n.svg"
                }
            }

          case "3t45x75":
            switch (chambers) {
              case "1":
                if (base === "estrutura") {
                  return "https://res.cloudinary.com/de6biukqo/image/upload/v1623323305/Configurador-Modulram/Finais/ilustra_%C3%A4es_modulram_2021_3T_1C_-_C%C3%A9PULA_-_ESTRUTURA_grukjf.svg"
                } else if (base === "estufa") {
                  return "https://res.cloudinary.com/de6biukqo/image/upload/v1623323306/Configurador-Modulram/Finais/ilustra_%C3%A4es_modulram_2021_3T_1C_-_C%C3%A9PULA_-_ESTUFA_wssgif.svg"
                }

              case "2":
                if (base === "estrutura") {
                  return "https://res.cloudinary.com/de6biukqo/image/upload/v1623323329/Configurador-Modulram/Finais/ilustra_%C3%A4es_modulram_2021_3T_2C_-_C%C3%A9PULA_-_ESTRUTURA_garuwk.svg"
                } else if (base === "estufa") {
                  return "https://res.cloudinary.com/de6biukqo/image/upload/v1623323329/Configurador-Modulram/Finais/ilustra_%C3%A4es_modulram_2021_3T_2C_-_C%C3%A9PULA_-_ESTUFA_gabioy.svg"
                }

              case "3":
                if (base === "estrutura") {
                  return "https://res.cloudinary.com/de6biukqo/image/upload/v1623323354/Configurador-Modulram/Finais/ilustra_%C3%A4es_modulram_2021_3T_3C_-_C%C3%A9PULA_-_ESTRUTURA_ncwq8z.svg"
                } else if (base === "estufa") {
                  return "https://res.cloudinary.com/de6biukqo/image/upload/v1623323354/Configurador-Modulram/Finais/ilustra_%C3%A4es_modulram_2021_3T_3C_-_C%C3%A9PULA_-_ESTUFA_h3ela1.svg"
                }

              case "4":
                if (base === "estrutura") {
                  return "https://res.cloudinary.com/de6biukqo/image/upload/v1623323376/Configurador-Modulram/Finais/ilustra_%C3%A4es_modulram_2021_3T_4C_-_C%C3%A9PULA_-_ESTRUTURA_nt2f9b.svg"
                } else if (base === "estufa") {
                  return "https://res.cloudinary.com/de6biukqo/image/upload/v1623323376/Configurador-Modulram/Finais/ilustra_%C3%A4es_modulram_2021_3T_4C_-_C%C3%A9PULA_-_ESTUFA_agpm65.svg"
                }

              case "5":
                if (base === "estrutura") {
                  return "https://res.cloudinary.com/de6biukqo/image/upload/v1623323398/Configurador-Modulram/Finais/ilustra_%C3%A4es_modulram_2021_3T_5C_-_C%C3%A9PULA_-_ESTRUTURA_fgchfb.svg"
                } else if (base === "estufa") {
                  return "https://res.cloudinary.com/de6biukqo/image/upload/v1623323398/Configurador-Modulram/Finais/ilustra_%C3%A4es_modulram_2021_3T_5C_-_C%C3%A9PULA_-_ESTUFA_udpacr.svg"
                }
            }

          case "2t45x75+2t45x75":
            switch (chambers) {
              case "1":
                if (base === "estrutura") {
                  return "https://res.cloudinary.com/de6biukqo/image/upload/v1623323458/Configurador-Modulram/Finais/ilustra_%C3%A4es_modulram_2021_2T_1C_-_C%C3%A9PULA_-_ESTRUTURA_giflyj.svg"
                } else if (base === "estufa") {
                  return "https://res.cloudinary.com/de6biukqo/image/upload/v1623323458/Configurador-Modulram/Finais/ilustra_%C3%A4es_modulram_2021_2T_1C_-_C%C3%A9PULA_-_ESTUFA_cwyxmy.svg"
                }

              case "2":
                if (base === "estrutura") {
                  return "https://res.cloudinary.com/de6biukqo/image/upload/v1623323479/Configurador-Modulram/Finais/ilustra_%C3%A4es_modulram_2021_2T_2C_-_C%C3%A9PULA_-_ESTRUTURA_nhgzlx.svg"
                } else if (base === "estufa") {
                  return "https://res.cloudinary.com/de6biukqo/image/upload/v1623323479/Configurador-Modulram/Finais/ilustra_%C3%A4es_modulram_2021_2T_2C_-_C%C3%A9PULA_-_ESTUFA_jy2sal.svg"
                }

              case "3":
                if (base === "estrutura") {
                  return "https://res.cloudinary.com/de6biukqo/image/upload/v1623323501/Configurador-Modulram/Finais/ilustra_%C3%A4es_modulram_2021_2T_3C_-_C%C3%A9PULA_-ESTRUTURA_peopkf.svg"
                } else if (base === "estufa") {
                  return "https://res.cloudinary.com/de6biukqo/image/upload/v1623323501/Configurador-Modulram/Finais/ilustra_%C3%A4es_modulram_2021_2T_3C_-_C%C3%A9PULA_-_ESTUFA_y84ffa.svg"
                }

              case "4":
                if (base === "estrutura") {
                  return "https://res.cloudinary.com/de6biukqo/image/upload/v1623323524/Configurador-Modulram/Finais/ilustra_%C3%A4es_modulram_2021_2T_4C_-_C%C3%A9PULA_-_ESTRUTURA_o8siat.svg"
                } else if (base === "estufa") {
                  return "https://res.cloudinary.com/de6biukqo/image/upload/v1623323523/Configurador-Modulram/Finais/ilustra_%C3%A4es_modulram_2021_2T_4C_-_C%C3%A9PULA_-_ESTUFA_vysgwk.svg"
                }

              case "5":
                if (base === "estrutura") {
                  return "https://res.cloudinary.com/de6biukqo/image/upload/v1623323547/Configurador-Modulram/Finais/ilustra_%C3%A4es_modulram_2021_2T_5C_-_C%C3%A9PULA_-_ESTRUTURA_dqhjro.svg"
                } else if (base === "estufa") {
                  return "https://res.cloudinary.com/de6biukqo/image/upload/v1623323547/Configurador-Modulram/Finais/ilustra_%C3%A4es_modulram_2021_2T_5C_-_C%C3%A9PULA_-_ESTUFA_i9ky5r.svg"
                }
            }
        }

      case "60x80":
        switch (trayPosition) {
          case "1t60x80":
            switch (chambers) {
              case "1":
                if (base === "estrutura") {
                  return "https://res.cloudinary.com/de6biukqo/image/upload/v1623323724/Configurador-Modulram/Finais/ilustra_%C3%A4es_modulram_2021_1T_1C_-_C%C3%A9PULA-_ESTRUTURA_icprsq.svg"
                } else if (base === "estufa") {
                  return "https://res.cloudinary.com/de6biukqo/image/upload/v1623323724/Configurador-Modulram/Finais/ilustra_%C3%A4es_modulram_2021_1T_1C_-_C%C3%A9PULA-ESTUFA_hzn0hx.svg"
                }

              case "2":
                if (base === "estrutura") {
                  return "https://res.cloudinary.com/de6biukqo/image/upload/v1623323750/Configurador-Modulram/Finais/ilustra_%C3%A4es_modulram_2021_1T_2C_-_C%C3%A9PULA_-_ESTRUTURA_um1fak.svg"
                } else if (base === "estufa") {
                  return "https://res.cloudinary.com/de6biukqo/image/upload/v1623323750/Configurador-Modulram/Finais/ilustra_%C3%A4es_modulram_2021_1T_2C_-_C%C3%A9PULA_-_ESTUFA_tfglcl.svg"
                }

              case "3":
                if (base === "estrutura") {
                  return "https://res.cloudinary.com/de6biukqo/image/upload/v1623323769/Configurador-Modulram/Finais/ilustra_%C3%A4es_modulram_2021_1T_3C_-_C%C3%A9PULA_-_ESTRUTURA_stl4al.svg"
                } else if (base === "estufa") {
                  return "https://res.cloudinary.com/de6biukqo/image/upload/v1623323769/Configurador-Modulram/Finais/ilustra_%C3%A4es_modulram_2021_1T_3C_-_C%C3%A9PULA_-_ESTUFA_vdmhdj.svg"
                }

              case "4":
                if (base === "estrutura") {
                  return "https://res.cloudinary.com/de6biukqo/image/upload/v1623323789/Configurador-Modulram/Finais/ilustra_%C3%A4es_modulram_2021_1T_4C_-_C%C3%A9PULA_-_ESTRUTURA_w0nlsr.svg"
                } else if (base === "estufa") {
                  return "https://res.cloudinary.com/de6biukqo/image/upload/v1623323789/Configurador-Modulram/Finais/ilustra_%C3%A4es_modulram_2021_1T_4C_-_C%C3%A9PULA_-_ESTUFA_aax0ww.svg"
                }

              case "5":
                if (base === "estrutura") {
                  return "https://res.cloudinary.com/de6biukqo/image/upload/v1623323809/Configurador-Modulram/Finais/ilustra_%C3%A4es_modulram_2021_1T_5C_-_C%C3%A9PULA_-_ESTRUTURA_bmhlk2.svg"
                } else if (base === "estufa") {
                  return "https://res.cloudinary.com/de6biukqo/image/upload/v1623323810/Configurador-Modulram/Finais/ilustra_%C3%A4es_modulram_2021_1T_5C_-_C%C3%A9PULA_-_ESTUFA_hg6fub.svg"
                }
            }

          case "1t80x60":
            switch (chambers) {
              case "1":
                if (base === "estrutura") {
                  return "https://res.cloudinary.com/de6biukqo/image/upload/v1623323873/Configurador-Modulram/Finais/ilustra_%C3%A4es_modulram_2021_2T_1C_-_C%C3%A9PULA_-_ESTRUTURA_rlhoaa.svg"
                } else if (base === "estufa") {
                  return "https://res.cloudinary.com/de6biukqo/image/upload/v1623323874/Configurador-Modulram/Finais/ilustra_%C3%A4es_modulram_2021_2T_1C_-_C%C3%A9PULA_-_ESTUFA_pijch8.svg"
                }

              case "2":
                if (base === "estrutura") {
                  return "https://res.cloudinary.com/de6biukqo/image/upload/v1623323896/Configurador-Modulram/Finais/ilustra_%C3%A4es_modulram_2021_2T_2C_-_C%C3%A9PULA_-_ESTRUTURA_hz9lyw.svg"
                } else if (base === "estufa") {
                  return "https://res.cloudinary.com/de6biukqo/image/upload/v1623323895/Configurador-Modulram/Finais/ilustra_%C3%A4es_modulram_2021_2T_2C_-_C%C3%A9PULA_-_ESTUFA_vygv70.svg"
                }

              case "3":
                if (base === "estrutura") {
                  return "https://res.cloudinary.com/de6biukqo/image/upload/v1623323919/Configurador-Modulram/Finais/ilustra_%C3%A4es_modulram_2021_2T_3C_-_C%C3%A9PULA_-ESTRUTURA_mzmlse.svg"
                } else if (base === "estufa") {
                  return "https://res.cloudinary.com/de6biukqo/image/upload/v1623323919/Configurador-Modulram/Finais/ilustra_%C3%A4es_modulram_2021_2T_3C_-_C%C3%A9PULA_-_ESTUFA_mjj64z.svg"
                }

              case "4":
                if (base === "estrutura") {
                  return "https://res.cloudinary.com/de6biukqo/image/upload/v1623323939/Configurador-Modulram/Finais/ilustra_%C3%A4es_modulram_2021_2T_4C_-_C%C3%A9PULA_-_ESTRUTURA_xkcoyt.svg"
                } else if (base === "estufa") {
                  return "https://res.cloudinary.com/de6biukqo/image/upload/v1623323939/Configurador-Modulram/Finais/ilustra_%C3%A4es_modulram_2021_2T_4C_-_C%C3%A9PULA_-_ESTUFA_vj3e4f.svg"
                }

              case "5":
                if (base === "estrutura") {
                  return "https://res.cloudinary.com/de6biukqo/image/upload/v1623323958/Configurador-Modulram/Finais/ilustra_%C3%A4es_modulram_2021_2T_5C_-_C%C3%A9PULA_-_ESTRUTURA_u03bcj.svg"
                } else if (base === "estufa") {
                  return "https://res.cloudinary.com/de6biukqo/image/upload/v1623323958/Configurador-Modulram/Finais/ilustra_%C3%A4es_modulram_2021_2T_5C_-_C%C3%A9PULA_-_ESTUFA_mb1bpw.svg"
                }
            }

          case "1t60x80+1t60x80":
            switch (chambers) {
              case "1":
                if (base === "estrutura") {
                  return "https://res.cloudinary.com/de6biukqo/image/upload/v1623324958/Configurador-Modulram/Finais/ilustra_%C3%A4es_modulram_2021_1T_1C_-_C%C3%A9PULA-_ESTRUTURA_yg8tu9.svg"
                } else if (base === "estufa") {
                  return "https://res.cloudinary.com/de6biukqo/image/upload/v1623324958/Configurador-Modulram/Finais/ilustra_%C3%A4es_modulram_2021_1T_1C_-_C%C3%A9PULA-ESTUFA_wxnxkz.svg"
                }

              case "2":
                if (base === "estrutura") {
                  return "https://res.cloudinary.com/de6biukqo/image/upload/v1623324980/Configurador-Modulram/Finais/ilustra_%C3%A4es_modulram_2021_1T_2C_-_C%C3%A9PULA_-_ESTRUTURA_ahnxha.svg"
                } else if (base === "estufa") {
                  return "https://res.cloudinary.com/de6biukqo/image/upload/v1623324979/Configurador-Modulram/Finais/ilustra_%C3%A4es_modulram_2021_1T_2C_-_C%C3%A9PULA_-_ESTUFA_tzf2ms.svg"
                }

              case "3":
                if (base === "estrutura") {
                  return "https://res.cloudinary.com/de6biukqo/image/upload/v1623325002/Configurador-Modulram/Finais/ilustra_%C3%A4es_modulram_2021_1T_3C_-_C%C3%A9PULA_-_ESTRUTURA_ql7yab.svg"
                } else if (base === "estufa") {
                  return "https://res.cloudinary.com/de6biukqo/image/upload/v1623325002/Configurador-Modulram/Finais/ilustra_%C3%A4es_modulram_2021_1T_3C_-_C%C3%A9PULA_-_ESTUFA_f3kpia.svg"
                }

              case "4":
                if (base === "estrutura") {
                  return "https://res.cloudinary.com/de6biukqo/image/upload/v1623325024/Configurador-Modulram/Finais/ilustra_%C3%A4es_modulram_2021_1T_4C_-_C%C3%A9PULA_-_ESTRUTURA_obc7z1.svg"
                } else if (base === "estufa") {
                  return "https://res.cloudinary.com/de6biukqo/image/upload/v1623325025/Configurador-Modulram/Finais/ilustra_%C3%A4es_modulram_2021_1T_4C_-_C%C3%A9PULA_-_ESTUFA_wsejte.svg"
                }

              case "5":
                if (base === "estrutura") {
                  return "https://res.cloudinary.com/de6biukqo/image/upload/v1623325044/Configurador-Modulram/Finais/ilustra_%C3%A4es_modulram_2021_1T_5C_-_C%C3%A9PULA_-_ESTRUTURA_ywah2y.svg"
                } else if (base === "estufa") {
                  return "https://res.cloudinary.com/de6biukqo/image/upload/v1623325045/Configurador-Modulram/Finais/ilustra_%C3%A4es_modulram_2021_1T_5C_-_C%C3%A9PULA_-_ESTUFA_wgrpjx.svg"
                }
            }

          case "1t80x60+1t80x60":
            switch (chambers) {
              case "1":
                if (base === "estrutura") {
                  return "https://res.cloudinary.com/de6biukqo/image/upload/v1623325116/Configurador-Modulram/Finais/ilustra_%C3%A4es_modulram_2021_2T_1C_-_C%C3%A9PULA_-_ESTRUTURA_exnelj.svg"
                } else if (base === "estufa") {
                  return "https://res.cloudinary.com/de6biukqo/image/upload/v1623325117/Configurador-Modulram/Finais/ilustra_%C3%A4es_modulram_2021_2T_1C_-_C%C3%A9PULA_-_ESTUFA_jke6zb.svg"
                }

              case "2":
                if (base === "estrutura") {
                  return "https://res.cloudinary.com/de6biukqo/image/upload/v1623325142/Configurador-Modulram/Finais/ilustra_%C3%A4es_modulram_2021_2T_2C_-_C%C3%A9PULA_-_ESTRUTURA_vosgiy.svg"
                } else if (base === "estufa") {
                  return "https://res.cloudinary.com/de6biukqo/image/upload/v1623325142/Configurador-Modulram/Finais/ilustra_%C3%A4es_modulram_2021_2T_2C_-_C%C3%A9PULA_-_ESTUFA_cyb3xz.svg"
                }

              case "3":
                if (base === "estrutura") {
                  return "https://res.cloudinary.com/de6biukqo/image/upload/v1623325165/Configurador-Modulram/Finais/ilustra_%C3%A4es_modulram_2021_2T_3C_-_C%C3%A9PULA_-ESTRUTURA_xrvdlc.svg"
                } else if (base === "estufa") {
                  return "https://res.cloudinary.com/de6biukqo/image/upload/v1623325165/Configurador-Modulram/Finais/ilustra_%C3%A4es_modulram_2021_2T_3C_-_C%C3%A9PULA_-_ESTUFA_hemysk.svg"
                }

              case "4":
                if (base === "estrutura") {
                  return "https://res.cloudinary.com/de6biukqo/image/upload/v1623325185/Configurador-Modulram/Finais/ilustra_%C3%A4es_modulram_2021_2T_4C_-_C%C3%A9PULA_-_ESTRUTURA_jk1v1q.svg"
                } else if (base === "estufa") {
                  return "https://res.cloudinary.com/de6biukqo/image/upload/v1623325185/Configurador-Modulram/Finais/ilustra_%C3%A4es_modulram_2021_2T_4C_-_C%C3%A9PULA_-_ESTUFA_kd7glj.svg"
                }

              case "5":
                if (base === "estrutura") {
                  return "https://res.cloudinary.com/de6biukqo/image/upload/v1623325210/Configurador-Modulram/Finais/ilustra_%C3%A4es_modulram_2021_2T_5C_-_C%C3%A9PULA_-_ESTRUTURA_sjty6d.svg"
                } else if (base === "estufa") {
                  return "https://res.cloudinary.com/de6biukqo/image/upload/v1623325210/Configurador-Modulram/Finais/ilustra_%C3%A4es_modulram_2021_2T_5C_-_C%C3%A9PULA_-_ESTUFA_bvg7fr.svg"
                }
            }

          case "2t60x80":
            switch (chambers) {
              case "1":
                if (base === "estrutura") {
                  return "https://res.cloudinary.com/de6biukqo/image/upload/v1623325271/Configurador-Modulram/Finais/ilustra_%C3%A4es_modulram_2021_2T_1C_-_C%C3%A9PULA_-_ESTRUTURA_qk9edk.svg"
                } else if (base === "estufa") {
                  return "https://res.cloudinary.com/de6biukqo/image/upload/v1623325271/Configurador-Modulram/Finais/ilustra_%C3%A4es_modulram_2021_2T_1C_-_C%C3%A9PULA_-_ESTUFA_nbtsfu.svg"
                }

              case "2":
                if (base === "estrutura") {
                  return "https://res.cloudinary.com/de6biukqo/image/upload/v1623325292/Configurador-Modulram/Finais/ilustra_%C3%A4es_modulram_2021_2T_2C_-_C%C3%A9PULA_-_ESTRUTURA_mpey8e.svg"
                } else if (base === "estufa") {
                  return "https://res.cloudinary.com/de6biukqo/image/upload/v1623325292/Configurador-Modulram/Finais/ilustra_%C3%A4es_modulram_2021_2T_2C_-_C%C3%A9PULA_-_ESTUFA_onnwox.svg"
                }

              case "3":
                if (base === "estrutura") {
                  return "https://res.cloudinary.com/de6biukqo/image/upload/v1623325312/Configurador-Modulram/Finais/ilustra_%C3%A4es_modulram_2021_2T_3C_-_C%C3%A9PULA_-ESTRUTURA_kb9jdr.svg"
                } else if (base === "estufa") {
                  return "https://res.cloudinary.com/de6biukqo/image/upload/v1623325312/Configurador-Modulram/Finais/ilustra_%C3%A4es_modulram_2021_2T_3C_-_C%C3%A9PULA_-_ESTUFA_m43hfd.svg"
                }

              case "4":
                if (base === "estrutura") {
                  return "https://res.cloudinary.com/de6biukqo/image/upload/v1623325332/Configurador-Modulram/Finais/ilustra_%C3%A4es_modulram_2021_2T_4C_-_C%C3%A9PULA_-_ESTRUTURA_dd8zlf.svg"
                } else if (base === "estufa") {
                  return "https://res.cloudinary.com/de6biukqo/image/upload/v1623325332/Configurador-Modulram/Finais/ilustra_%C3%A4es_modulram_2021_2T_4C_-_C%C3%A9PULA_-_ESTUFA_sm2nx1.svg"
                }

              case "5":
                if (base === "estrutura") {
                  return "https://res.cloudinary.com/de6biukqo/image/upload/v1623325352/Configurador-Modulram/Finais/ilustra_%C3%A4es_modulram_2021_2T_5C_-_C%C3%A9PULA_-_ESTRUTURA_bwfunh.svg"
                } else if (base === "estufa") {
                  return "https://res.cloudinary.com/de6biukqo/image/upload/v1623325352/Configurador-Modulram/Finais/ilustra_%C3%A4es_modulram_2021_2T_5C_-_C%C3%A9PULA_-_ESTUFA_smekb9.svg"
                }
            }

          case "2t60x80+2t60x80":
            switch (chambers) {
              case "1":
                if (base === "estrutura") {
                  return "https://res.cloudinary.com/de6biukqo/image/upload/v1623325801/Configurador-Modulram/Finais/ilustra_%C3%A4es_modulram_2021_2T_1C_-_C%C3%A9PULA_-_ESTRUTURA_oida4b.svg"
                } else if (base === "estufa") {
                  return "https://res.cloudinary.com/de6biukqo/image/upload/v1623325801/Configurador-Modulram/Finais/ilustra_%C3%A4es_modulram_2021_2T_1C_-_C%C3%A9PULA_-_ESTUFA_ac7kkb.svg"
                }

              case "2":
                if (base === "estrutura") {
                  return "https://res.cloudinary.com/de6biukqo/image/upload/v1623325821/Configurador-Modulram/Finais/ilustra_%C3%A4es_modulram_2021_2T_2C_-_C%C3%A9PULA_-_ESTRUTURA_ztckgf.svg"
                } else if (base === "estufa") {
                  return "https://res.cloudinary.com/de6biukqo/image/upload/v1623325821/Configurador-Modulram/Finais/ilustra_%C3%A4es_modulram_2021_2T_2C_-_C%C3%A9PULA_-_ESTUFA_fgyykp.svg"
                }

              case "3":
                if (base === "estrutura") {
                  return "https://res.cloudinary.com/de6biukqo/image/upload/v1623325842/Configurador-Modulram/Finais/ilustra_%C3%A4es_modulram_2021_2T_3C_-_C%C3%A9PULA_-ESTRUTURA_v2bd8k.svg"
                } else if (base === "estufa") {
                  return "https://res.cloudinary.com/de6biukqo/image/upload/v1623325842/Configurador-Modulram/Finais/ilustra_%C3%A4es_modulram_2021_2T_3C_-_C%C3%A9PULA_-_ESTUFA_dx1avj.svg"
                }

              case "4":
                if (base === "estrutura") {
                  return "https://res.cloudinary.com/de6biukqo/image/upload/v1623325860/Configurador-Modulram/Finais/ilustra_%C3%A4es_modulram_2021_2T_4C_-_C%C3%A9PULA_-_ESTRUTURA_lyguiu.svg"
                } else if (base === "estufa") {
                  return "https://res.cloudinary.com/de6biukqo/image/upload/v1623325860/Configurador-Modulram/Finais/ilustra_%C3%A4es_modulram_2021_2T_4C_-_C%C3%A9PULA_-_ESTUFA_ggkrl1.svg"
                }

              case "5":
                if (base === "estrutura") {
                  return "https://res.cloudinary.com/de6biukqo/image/upload/v1623325888/Configurador-Modulram/Finais/ilustra_%C3%A4es_modulram_2021_2T_5C_-_C%C3%A9PULA_-_ESTRUTURA_hxpwu8.svg"
                } else if (base === "estufa") {
                  return "https://res.cloudinary.com/de6biukqo/image/upload/v1623325888/Configurador-Modulram/Finais/ilustra_%C3%A4es_modulram_2021_2T_5C_-_C%C3%A9PULA_-_ESTUFA_hfmkbn.svg"
                }
            }
        }
    }
  } else if (model === "pizzaolo") {
    switch (pizzasQuantity) {
      case "3p":
        if (pizzasPosition === "3pa") {
          switch (chambers) {
            case "1":
              if (base === "estrutura") {
                return "https://res.cloudinary.com/de6biukqo/image/upload/v1623424999/Configurador-Modulram/Finais/ilustra_%C3%A4es_modulram_2021_2T_1C_-_C%C3%A9PULA_-_ESTRUTURA_oi9apf.svg"
              } else if (base === "estufa") {
                return "https://res.cloudinary.com/de6biukqo/image/upload/v1623425014/Configurador-Modulram/Finais/ilustra_%C3%A4es_modulram_2021_2T_1C_-_C%C3%A9PULA_-_ESTUFA_siofk0.svg"
              }

            case "2":
              if (base === "estrutura") {
                return "https://res.cloudinary.com/de6biukqo/image/upload/v1623425053/Configurador-Modulram/Finais/ilustra_%C3%A4es_modulram_2021_2T_2C_-_C%C3%A9PULA_-_ESTRUTURA_klyt1u.svg"
              } else if (base === "estufa") {
                return "https://res.cloudinary.com/de6biukqo/image/upload/v1623425064/Configurador-Modulram/Finais/ilustra_%C3%A4es_modulram_2021_2T_2C_-_C%C3%A9PULA_-_ESTUFA_nutwpk.svg"
              }

            case "3":
              if (base === "estrutura") {
                return "https://res.cloudinary.com/de6biukqo/image/upload/v1623973167/Configurador-Modulram/Finais/ilustra_%C3%A4es_modulram_2021_2T_3C_-_C%C3%A9PULA_-ESTRUTURA_sqqfar.svg"
              } else if (base === "estufa") {
                return "https://res.cloudinary.com/de6biukqo/image/upload/v1623973153/Configurador-Modulram/Finais/ilustra_%C3%A4es_modulram_2021_2T_3C_-_C%C3%A9PULA_-_ESTUFA_hkwobl.svg"
              }

            case "4":
              if (base === "estrutura") {
                return "https://res.cloudinary.com/de6biukqo/image/upload/v1623973183/Configurador-Modulram/Finais/ilustra_%C3%A4es_modulram_2021_2T_4C_-_C%C3%A9PULA_-_ESTRUTURA_zb2jlc.svg"
              } else if (base === "estufa") {
                return "https://res.cloudinary.com/de6biukqo/image/upload/v1623973197/Configurador-Modulram/Finais/ilustra_%C3%A4es_modulram_2021_2T_4C_-_C%C3%A9PULA_-_ESTUFA_hxpmfw.svg"
              }

            case "5":
              if (base === "estrutura") {
                return "https://res.cloudinary.com/de6biukqo/image/upload/v1623973209/Configurador-Modulram/Finais/ilustra_%C3%A4es_modulram_2021_2T_5C_-_C%C3%A9PULA_-_ESTRUTURA_kjzrih.svg"
              } else if (base === "estufa") {
                return "https://res.cloudinary.com/de6biukqo/image/upload/v1623973220/Configurador-Modulram/Finais/ilustra_%C3%A4es_modulram_2021_2T_5C_-_C%C3%A9PULA_-_ESTUFA_ocn9ak.svg"
              }
          }
        } else if (pizzasPosition === "3pb") {
          switch (chambers) {
            case "1":
              if (base === "estrutura") {
                return "https://res.cloudinary.com/de6biukqo/image/upload/v1623425098/Configurador-Modulram/Finais/ilustra_%C3%A4es_modulram_2021_1T_1C_-_C%C3%A9PULA-_ESTRUTURA_qtaiwy.svg"
              } else if (base === "estufa") {
                return "https://res.cloudinary.com/de6biukqo/image/upload/v1623425110/Configurador-Modulram/Finais/ilustra_%C3%A4es_modulram_2021_1T_1C_-_C%C3%A9PULA-ESTUFA_jngrge.svg"
              }

            case "2":
              if (base === "estrutura") {
                return "https://res.cloudinary.com/de6biukqo/image/upload/v1623425138/Configurador-Modulram/Finais/ilustra_%C3%A4es_modulram_2021_1T_2C_-_C%C3%A9PULA_-_ESTRUTURA_ghje8d.svg"
              } else if (base === "estufa") {
                return "https://res.cloudinary.com/de6biukqo/image/upload/v1623425149/Configurador-Modulram/Finais/ilustra_%C3%A4es_modulram_2021_1T_2C_-_C%C3%A9PULA_-_ESTUFA_zr6gc2.svg"
              }

            case "3":
              if (base === "estrutura") {
                return "https://res.cloudinary.com/de6biukqo/image/upload/v1623973265/Configurador-Modulram/Finais/ilustra_%C3%A4es_modulram_2021_1T_3C_-_C%C3%A9PULA_-_ESTRUTURA_nn4ksh.svg"
              } else if (base === "estufa") {
                return "https://res.cloudinary.com/de6biukqo/image/upload/v1623973284/Configurador-Modulram/Finais/ilustra_%C3%A4es_modulram_2021_1T_3C_-_C%C3%A9PULA_-_ESTUFA_ja389o.svg"
              }

            case "4":
              if (base === "estrutura") {
                return "https://res.cloudinary.com/de6biukqo/image/upload/v1623973311/Configurador-Modulram/Finais/ilustra_%C3%A4es_modulram_2021_1T_4C_-_C%C3%A9PULA_-_ESTRUTURA_tedc4h.svg"
              } else if (base === "estufa") {
                return "https://res.cloudinary.com/de6biukqo/image/upload/v1623973321/Configurador-Modulram/Finais/ilustra_%C3%A4es_modulram_2021_1T_4C_-_C%C3%A9PULA_-_ESTUFA_uuc2o4.svg"
              }

            case "5":
              if (base === "estrutura") {
                return "https://res.cloudinary.com/de6biukqo/image/upload/v1623973334/Configurador-Modulram/Finais/ilustra_%C3%A4es_modulram_2021_1T_5C_-_C%C3%A9PULA_-_ESTRUTURA_xy2ece.svg"
              } else if (base === "estufa") {
                return "https://res.cloudinary.com/de6biukqo/image/upload/v1623973346/Configurador-Modulram/Finais/ilustra_%C3%A4es_modulram_2021_1T_5C_-_C%C3%A9PULA_-_ESTUFA_hbea7o.svg"
              }
          }
        }

      case "6p":
        switch (chambers) {
          case "1":
            if (base === "estrutura") {
              return "https://res.cloudinary.com/de6biukqo/image/upload/v1623425235/Configurador-Modulram/Finais/ilustra_%C3%A4es_modulram_2021_2T_1C_-_C%C3%A9PULA_-_ESTRUTURA_vqsb2w.svg"
            } else if (base === "estufa") {
              return "https://res.cloudinary.com/de6biukqo/image/upload/v1623425247/Configurador-Modulram/Finais/ilustra_%C3%A4es_modulram_2021_2T_1C_-_C%C3%A9PULA_-_ESTUFA_rpxvfo.svg"
            }

          case "2":
            if (base === "estrutura") {
              return "https://res.cloudinary.com/de6biukqo/image/upload/v1623425263/Configurador-Modulram/Finais/ilustra_%C3%A4es_modulram_2021_2T_2C_-_C%C3%A9PULA_-_ESTRUTURA_kenqjb.svg"
            } else if (base === "estufa") {
              return "https://res.cloudinary.com/de6biukqo/image/upload/v1623425274/Configurador-Modulram/Finais/ilustra_%C3%A4es_modulram_2021_2T_2C_-_C%C3%A9PULA_-_ESTUFA_wlyzr3.svg"
            }

          case "3":
            if (base === "estrutura") {
              return "https://res.cloudinary.com/de6biukqo/image/upload/v1623973415/Configurador-Modulram/Finais/ilustra_%C3%A4es_modulram_2021_2T_3C_-_C%C3%A9PULA_-ESTRUTURA_bwc0t1.svg"
            } else if (base === "estufa") {
              return "https://res.cloudinary.com/de6biukqo/image/upload/v1623973398/Configurador-Modulram/Finais/ilustra_%C3%A4es_modulram_2021_2T_3C_-_C%C3%A9PULA_-_ESTUFA_gzpygq.svg"
            }

          case "4":
            if (base === "estrutura") {
              return "https://res.cloudinary.com/de6biukqo/image/upload/v1623973434/Configurador-Modulram/Finais/ilustra_%C3%A4es_modulram_2021_2T_4C_-_C%C3%A9PULA_-_ESTRUTURA_zbddfa.svg"
            } else if (base === "estufa") {
              return "https://res.cloudinary.com/de6biukqo/image/upload/v1623973446/Configurador-Modulram/Finais/ilustra_%C3%A4es_modulram_2021_2T_4C_-_C%C3%A9PULA_-_ESTUFA_dttduc.svg"
            }

          case "5":
            if (base === "estrutura") {
              return "https://res.cloudinary.com/de6biukqo/image/upload/v1623973462/Configurador-Modulram/Finais/ilustra_%C3%A4es_modulram_2021_2T_5C_-_C%C3%A9PULA_-_ESTRUTURA_yoc01x.svg"
            } else if (base === "estufa") {
              return "https://res.cloudinary.com/de6biukqo/image/upload/v1623973474/Configurador-Modulram/Finais/ilustra_%C3%A4es_modulram_2021_2T_5C_-_C%C3%A9PULA_-_ESTUFA_doun4i.svg"
            }
        }

      case "8p":
        switch (chambers) {
          case "1":
            if (base === "estrutura") {
              return "https://res.cloudinary.com/de6biukqo/image/upload/v1623425309/Configurador-Modulram/Finais/ilustra_%C3%A4es_modulram_2021_3T_1C_-_C%C3%A9PULA_-_ESTRUTURA_re7m3i.svg"
            } else if (base === "estufa") {
              return "https://res.cloudinary.com/de6biukqo/image/upload/v1623425319/Configurador-Modulram/Finais/ilustra_%C3%A4es_modulram_2021_3T_1C_-_C%C3%A9PULA_-_ESTUFA_lzdspq.svg"
            }

          case "2":
            if (base === "estrutura") {
              return "https://res.cloudinary.com/de6biukqo/image/upload/v1623425331/Configurador-Modulram/Finais/ilustra_%C3%A4es_modulram_2021_3T_2C_-_C%C3%A9PULA_-_ESTRUTURA_erie82.svg"
            } else if (base === "estufa") {
              return "https://res.cloudinary.com/de6biukqo/image/upload/v1623425341/Configurador-Modulram/Finais/ilustra_%C3%A4es_modulram_2021_3T_2C_-_C%C3%A9PULA_-_ESTUFA_n4vg3z.svg"
            }

          case "3":
            if (base === "estrutura") {
              return "https://res.cloudinary.com/de6biukqo/image/upload/v1623973539/Configurador-Modulram/Finais/ilustra_%C3%A4es_modulram_2021_3T_3C_-_C%C3%A9PULA_-_ESTRUTURA_yfw1vh.svg"
            } else if (base === "estufa") {
              return "https://res.cloudinary.com/de6biukqo/image/upload/v1623973529/Configurador-Modulram/Finais/ilustra_%C3%A4es_modulram_2021_3T_3C_-_C%C3%A9PULA_-_ESTUFA_jqpwg0.svg"
            }

          case "4":
            if (base === "estrutura") {
              return "https://res.cloudinary.com/de6biukqo/image/upload/v1623973558/Configurador-Modulram/Finais/ilustra_%C3%A4es_modulram_2021_3T_4C_-_C%C3%A9PULA_-_ESTRUTURA_key2yc.svg"
            } else if (base === "estufa") {
              return "https://res.cloudinary.com/de6biukqo/image/upload/v1623973567/Configurador-Modulram/Finais/ilustra_%C3%A4es_modulram_2021_3T_4C_-_C%C3%A9PULA_-_ESTUFA_c15dpj.svg"
            }

          case "5":
            if (base === "estrutura") {
              return "https://res.cloudinary.com/de6biukqo/image/upload/v1623973581/Configurador-Modulram/Finais/ilustra_%C3%A4es_modulram_2021_3T_5C_-_C%C3%A9PULA_-_ESTRUTURA_i0tlc8.svg"
            } else if (base === "estufa") {
              return "https://res.cloudinary.com/de6biukqo/image/upload/v1623425341/Configurador-Modulram/Finais/ilustra_%C3%A4es_modulram_2021_3T_2C_-_C%C3%A9PULA_-_ESTUFA_n4vg3z.svg"
            }
        }

      case "9p":
        switch (chambers) {
          case "1":
            if (base === "estrutura") {
              return "https://res.cloudinary.com/de6biukqo/image/upload/v1623425365/Configurador-Modulram/Finais/ilustra_%C3%A4es_modulram_2021_2T_1C_-_C%C3%A9PULA_-_ESTRUTURA_dnp3ab.svg"
            } else if (base === "estufa") {
              return "https://res.cloudinary.com/de6biukqo/image/upload/v1623973591/Configurador-Modulram/Finais/ilustra_%C3%A4es_modulram_2021_3T_5C_-_C%C3%A9PULA_-_ESTUFA_h3fyik.svg"
            }

          case "2":
            if (base === "estrutura") {
              return "https://res.cloudinary.com/de6biukqo/image/upload/v1623425394/Configurador-Modulram/Finais/ilustra_%C3%A4es_modulram_2021_2T_2C_-_C%C3%A9PULA_-_ESTRUTURA_q7jn6g.svg"
            } else if (base === "estufa") {
              return "https://res.cloudinary.com/de6biukqo/image/upload/v1623425405/Configurador-Modulram/Finais/ilustra_%C3%A4es_modulram_2021_2T_2C_-_C%C3%A9PULA_-_ESTUFA_t875uv.svg"
            }

          case "3":
            if (base === "estrutura") {
              return "https://res.cloudinary.com/de6biukqo/image/upload/v1623973641/Configurador-Modulram/Finais/ilustra_%C3%A4es_modulram_2021_2T_3C_-_C%C3%A9PULA_-ESTRUTURA_vkc38d.svg"
            } else if (base === "estufa") {
              return "https://res.cloudinary.com/de6biukqo/image/upload/v1623973630/Configurador-Modulram/Finais/ilustra_%C3%A4es_modulram_2021_2T_3C_-_C%C3%A9PULA_-_ESTUFA_lijyrl.svg"
            }

          case "4":
            if (base === "estrutura") {
              return "https://res.cloudinary.com/de6biukqo/image/upload/v1623973683/Configurador-Modulram/Finais/ilustra_%C3%A4es_modulram_2021_2T_4C_-_C%C3%A9PULA_-_ESTRUTURA_x0kxmy.svg"
            } else if (base === "estufa") {
              return "https://res.cloudinary.com/de6biukqo/image/upload/v1623973693/Configurador-Modulram/Finais/ilustra_%C3%A4es_modulram_2021_2T_4C_-_C%C3%A9PULA_-_ESTUFA_mypvu0.svg"
            }

          case "5":
            if (base === "estrutura") {
              return "https://res.cloudinary.com/de6biukqo/image/upload/v1623973711/Configurador-Modulram/Finais/ilustra_%C3%A4es_modulram_2021_2T_5C_-_C%C3%A9PULA_-_ESTRUTURA_budggr.svg"
            } else if (base === "estufa") {
              return "https://res.cloudinary.com/de6biukqo/image/upload/v1623973724/Configurador-Modulram/Finais/ilustra_%C3%A4es_modulram_2021_2T_5C_-_C%C3%A9PULA_-_ESTUFA_lddnvu.svg"
            }
        }

      case "12p":
        switch (chambers) {
          case "1":
            if (base === "estrutura") {
              return "https://res.cloudinary.com/de6biukqo/image/upload/v1623425471/Configurador-Modulram/Finais/ilustra_%C3%A4es_modulram_2021_3T_1C_-_C%C3%A9PULA_-_ESTRUTURA_fuch8h.svg"
            } else if (base === "estufa") {
              return "https://res.cloudinary.com/de6biukqo/image/upload/v1623425482/Configurador-Modulram/Finais/ilustra_%C3%A4es_modulram_2021_3T_1C_-_C%C3%A9PULA_-_ESTUFA_ivx2zt.svg"
            }

          case "2":
            if (base === "estrutura") {
              return "https://res.cloudinary.com/de6biukqo/image/upload/v1623425498/Configurador-Modulram/Finais/ilustra_%C3%A4es_modulram_2021_3T_2C_-_C%C3%A9PULA_-_ESTRUTURA_h8slxf.svg"
            } else if (base === "estufa") {
              return "https://res.cloudinary.com/de6biukqo/image/upload/v1623425512/Configurador-Modulram/Finais/ilustra_%C3%A4es_modulram_2021_3T_2C_-_C%C3%A9PULA_-_ESTUFA_h24uqe.svg"
            }

          case "3":
            if (base === "estrutura") {
              return "https://res.cloudinary.com/de6biukqo/image/upload/v1623973800/Configurador-Modulram/Finais/ilustra_%C3%A4es_modulram_2021_3T_3C_-_C%C3%A9PULA_-_ESTRUTURA_p8szz7.svg"
            } else if (base === "estufa") {
              return "https://res.cloudinary.com/de6biukqo/image/upload/v1623973789/Configurador-Modulram/Finais/ilustra_%C3%A4es_modulram_2021_3T_3C_-_C%C3%A9PULA_-_ESTUFA_hz5bcx.svg"
            }

          case "4":
            if (base === "estrutura") {
              return "https://res.cloudinary.com/de6biukqo/image/upload/v1623973828/Configurador-Modulram/Finais/ilustra_%C3%A4es_modulram_2021_3T_4C_-_C%C3%A9PULA_-_ESTRUTURA_jgyfjj.svg"
            } else if (base === "estufa") {
              return "https://res.cloudinary.com/de6biukqo/image/upload/v1623973837/Configurador-Modulram/Finais/ilustra_%C3%A4es_modulram_2021_3T_4C_-_C%C3%A9PULA_-_ESTUFA_elzjrc.svg"
            }

          case "5":
            if (base === "estrutura") {
              return "https://res.cloudinary.com/de6biukqo/image/upload/v1623973854/Configurador-Modulram/Finais/ilustra_%C3%A4es_modulram_2021_3T_5C_-_C%C3%A9PULA_-_ESTRUTURA_mtqdzg.svg"
            } else if (base === "estufa") {
              return "https://res.cloudinary.com/de6biukqo/image/upload/v1623973864/Configurador-Modulram/Finais/ilustra_%C3%A4es_modulram_2021_3T_5C_-_C%C3%A9PULA_-_ESTUFA_p5lra7.svg"
            }
        }
    }
  }
}
